'use client'
import React, { useEffect, useState } from 'react'
import Image from 'next/image'

import styles from './ImageGrid.module.scss'

const ImageGrid = ({ images }: any): React.ReactNode => {
    const [ activeImg, setActiveImg ] = useState<string | null>()

    useEffect(() => {
        const checkOutsideClick = (e: MouseEvent) => {
            const popup = document.getElementById('popup')
            const img = document.getElementById('bigImage')
            const target: HTMLElement = e.target as HTMLElement

            if(popup && img && target){
                if(!img.contains(target) && popup.contains(target)){
                    console.log(target, img, !img.contains(target))
                    setActiveImg(null)
                }
            }
        }

        window.addEventListener('click', checkOutsideClick)

        return () => window.removeEventListener('click', checkOutsideClick)
    }, [])

    return(
        <div className={styles.imageGrid}>
            {activeImg && <div className={styles.popup} onClick={() => setActiveImg(null)} id='popup'>
                <div className={styles.popup__close}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 384 512"
                         width='32px'
                         height='32px'
                         fill='#ffffff'
                    >
                        <path
                            d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9
                            25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1
                            45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5
                            4.1-45.1L233.7 256 376.6 84.5z"/>
                    </svg>
                </div>
                <div className={styles.popup__img}>
                    <Image
                        id='bigImage'
                        src={activeImg}
                        width={3024}
                        height={4032}
                        alt='Tattoo Image'
                    />
                </div>
            </div>}
            {images.map((path: string, k: number) => (
                <div className={styles.imageGrid__img} key={k}>
                    <Image
                        src={path}
                        width={218}
                        height={218}
                        onClick={() => setActiveImg(path)}
                        style={{
                            objectFit: 'cover',
                            objectPosition: '50% 50%'
                        }}
                        alt='Tattoo Image'
                    />
                </div>
            ))}
        </div>
    )
}

export default ImageGrid
